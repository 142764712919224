:root {
  --md-source: #e5eedb;
  /* primary */
  --md-ref-palette-primary0: #000000;
  --md-ref-palette-primary10: #042100;
  --md-ref-palette-primary20: #0b3900;
  --md-ref-palette-primary25: #0f4500;
  --md-ref-palette-primary30: #1c520a;
  --md-ref-palette-primary35: #285e16;
  --md-ref-palette-primary40: #346a22;
  --md-ref-palette-primary50: #4d8438;
  --md-ref-palette-primary60: #669f50;
  --md-ref-palette-primary70: #7fba67;
  --md-ref-palette-primary80: #9ad680;
  --md-ref-palette-primary90: #b5f39a;
  --md-ref-palette-primary95: #ccffb3;
  --md-ref-palette-primary98: #edffe0;
  --md-ref-palette-primary99: #f7ffed;
  --md-ref-palette-primary100: #ffffff;
  /* secondary */
  --md-ref-palette-secondary0: #000000;
  --md-ref-palette-secondary10: #131f0e;
  --md-ref-palette-secondary20: #273421;
  --md-ref-palette-secondary25: #323f2b;
  --md-ref-palette-secondary30: #3d4b36;
  --md-ref-palette-secondary35: #495641;
  --md-ref-palette-secondary40: #54624c;
  --md-ref-palette-secondary50: #6d7b64;
  --md-ref-palette-secondary60: #86957d;
  --md-ref-palette-secondary70: #a1b096;
  --md-ref-palette-secondary80: #bccbb0;
  --md-ref-palette-secondary90: #d8e7cc;
  --md-ref-palette-secondary95: #e6f6d9;
  --md-ref-palette-secondary98: #effee2;
  --md-ref-palette-secondary99: #f7ffed;
  --md-ref-palette-secondary100: #ffffff;
  /* tertiary */
  --md-ref-palette-tertiary0: #000000;
  --md-ref-palette-tertiary10: #002021;
  --md-ref-palette-tertiary20: #003738;
  --md-ref-palette-tertiary25: #0f4244;
  --md-ref-palette-tertiary30: #1e4e4f;
  --md-ref-palette-tertiary35: #2b595b;
  --md-ref-palette-tertiary40: #386667;
  --md-ref-palette-tertiary50: #517f80;
  --md-ref-palette-tertiary60: #6b999a;
  --md-ref-palette-tertiary70: #85b3b5;
  --md-ref-palette-tertiary80: #a0cfd1;
  --md-ref-palette-tertiary90: #bcebed;
  --md-ref-palette-tertiary95: #cafafb;
  --md-ref-palette-tertiary98: #e4feff;
  --md-ref-palette-tertiary99: #f1ffff;
  --md-ref-palette-tertiary100: #ffffff;
  /* neutral */
  --md-ref-palette-neutral0: #000000;
  --md-ref-palette-neutral10: #1a1c18;
  --md-ref-palette-neutral20: #2f312d;
  --md-ref-palette-neutral25: #3a3c37;
  --md-ref-palette-neutral30: #464743;
  --md-ref-palette-neutral35: #51534e;
  --md-ref-palette-neutral40: #5d5f5a;
  --md-ref-palette-neutral50: #767872;
  --md-ref-palette-neutral60: #90918b;
  --md-ref-palette-neutral70: #abaca6;
  --md-ref-palette-neutral80: #c6c7c1;
  --md-ref-palette-neutral90: #e3e3dc;
  --md-ref-palette-neutral95: #f1f1ea;
  --md-ref-palette-neutral98: #fafaf3;
  --md-ref-palette-neutral99: #fdfdf6;
  --md-ref-palette-neutral100: #ffffff;
  /* neutral-variant */
  --md-ref-palette-neutral-variant0: #000000;
  --md-ref-palette-neutral-variant10: #181d15;
  --md-ref-palette-neutral-variant20: #2d3229;
  --md-ref-palette-neutral-variant25: #383d34;
  --md-ref-palette-neutral-variant30: #43483f;
  --md-ref-palette-neutral-variant35: #4f544a;
  --md-ref-palette-neutral-variant40: #5b6056;
  --md-ref-palette-neutral-variant50: #73796e;
  --md-ref-palette-neutral-variant60: #8d9387;
  --md-ref-palette-neutral-variant70: #a8ada1;
  --md-ref-palette-neutral-variant80: #c3c8bb;
  --md-ref-palette-neutral-variant90: #dfe4d7;
  --md-ref-palette-neutral-variant95: #eef3e5;
  --md-ref-palette-neutral-variant98: #f6fbed;
  --md-ref-palette-neutral-variant99: #f9fef0;
  --md-ref-palette-neutral-variant100: #ffffff;
  /* error */
  --md-ref-palette-error0: #000000;
  --md-ref-palette-error10: #410002;
  --md-ref-palette-error20: #690005;
  --md-ref-palette-error25: #7e0007;
  --md-ref-palette-error30: #93000a;
  --md-ref-palette-error35: #a80710;
  --md-ref-palette-error40: #ba1a1a;
  --md-ref-palette-error50: #de3730;
  --md-ref-palette-error60: #ff5449;
  --md-ref-palette-error70: #ff897d;
  --md-ref-palette-error80: #ffb4ab;
  --md-ref-palette-error90: #ffdad6;
  --md-ref-palette-error95: #ffedea;
  --md-ref-palette-error98: #fff8f7;
  --md-ref-palette-error99: #fffbff;
  --md-ref-palette-error100: #ffffff;
  /* light */
  --md-sys-color-primary-light: #9ad680;
  --md-sys-color-on-primary-light: #ffffff;
  --md-sys-color-primary-container-light: #b5f39a;
  --md-sys-color-on-primary-container-light: #042100;
  --md-sys-color-secondary-light: #54624c;
  --md-sys-color-on-secondary-light: #ffffff;
  --md-sys-color-secondary-container-light: #d8e7ccd1;
  --md-sys-color-on-secondary-container-light: #131f0e;
  --md-sys-color-tertiary-light: #009700;
  --md-sys-color-on-tertiary-light: #9ad680;
  --md-sys-color-tertiary-container-light: #bcebed;
  --md-sys-color-on-tertiary-container-light: #002021;
  --md-sys-color-error-light: #ba1a1a;
  --md-sys-color-error-container-light: transparent;
  --md-sys-color-on-error-light: #ffffff;
  --md-sys-color-on-error-container-light: #41000266;
  --md-sys-color-background-light: #fdfdf6;
  --md-sys-color-on-background-light: #1a1c18;
  --md-sys-color-surface-light: #fdfdf6;
  --md-sys-color-on-surface-light: #1a1c18;
  --md-sys-color-surface-variant-light: #dfe4d7;
  --md-sys-color-on-surface-variant-light: #43483f;
  --md-sys-color-outline-light: #73796e;
  --md-sys-color-inverse-on-surface-light: #f1f1ea;
  --md-sys-color-inverse-surface-light: #2f312d;
  --md-sys-color-inverse-primary-light: #9ad680;
  --md-sys-color-shadow-light: #000000;
  --md-sys-color-surface-tint-light: #346a22;
  --md-sys-color-outline-variant-light: #c3c8bb;
  --md-sys-color-scrim-light: #000000;
  --md-sys-color-link-light: #5C85FF;
  --md-sys-color-warning-notification-background-light: #FFFAF2;
  --md-sys-color-warning-notification-icon-fill-light: #FFB020;
  --md-sys-color-success-notification-background-light: #f5fbf8;
  --md-sys-color-error-container-background-light: #FDF4F4;
  --md-sys-color-chip-background-light: #00000014;
  --md-sys-color-box-shadow-top-light: rgba(67, 90, 111, 0.3); 
  --md-sys-color-box-shadow-bottom-light: rgba(67, 90, 111, 0.47); 
  --md-sys-color-navbar-box-shadow-light: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
  --md-sys-color-bg-light: #f9fafce3;
  --md-sys-color-card-bg-light: linear-gradient(to top, #d8e7cc99, transparent);
  --md-sys-color-skeleton-bg-light: rgba(0, 0, 0, 0.11);
  --md-sys-color-text-light: #000000;
  --md-sys-effect-media-light: 1.1;
  --md-sys-card-bg-image-color-light: rgba(0, 0, 0, 0.07);

  /* dark */
  --md-sys-color-primary-dark: #346a22;
  --md-sys-color-on-primary-dark: #0b3900;
  --md-sys-color-primary-container-dark: #1c520a;
  --md-sys-color-on-primary-container-dark: #b5f39a;
  --md-sys-color-secondary-dark: #bccbb0;
  --md-sys-color-on-secondary-dark: #273421;
  --md-sys-color-secondary-container-dark: #3d4b36d1;
  --md-sys-color-on-secondary-container-dark: #d8e7cc;
  --md-sys-color-tertiary-dark: #3498DB;
  --md-sys-color-on-tertiary-dark: #003738;
  --md-sys-color-tertiary-container-dark: #1e4e4f;
  --md-sys-color-on-tertiary-container-dark: #bcebed;
  --md-sys-color-error-dark: #ffb4ab;
  --md-sys-color-error-container-dark: #93000a12;
  --md-sys-color-on-error-dark: #690005;
  --md-sys-color-on-error-container-dark: #ffdad666;
  --md-sys-color-background-dark: #1a1c18;
  --md-sys-color-on-background-dark: #e3e3dc;
  --md-sys-color-surface-dark: #1a1c18;
  --md-sys-color-on-surface-dark: #e3e3dc;
  --md-sys-color-surface-variant-dark: #43483f;
  --md-sys-color-on-surface-variant-dark: #c3c8bb;
  --md-sys-color-outline-dark: #8d9387;
  --md-sys-color-inverse-on-surface-dark: #1a1c18;
  --md-sys-color-inverse-surface-dark: #e3e3dc;
  --md-sys-color-inverse-primary-dark: #346a22;
  --md-sys-color-shadow-dark: #000000;
  --md-sys-color-surface-tint-dark: #9ad680;
  --md-sys-color-outline-variant-dark: #43483f;
  --md-sys-color-scrim-dark: #000000;
  --md-sys-color-link-dark: #001b57;
  --md-sys-color-warning-notification-background-dark: #996a23;
  --md-sys-color-warning-notification-icon-fill-dark: #fbff00;
  --md-sys-color-success-notification-background-dark: #317159;
  --md-sys-color-error-container-background-dark: #000000;
  --md-sys-color-chip-background-dark: #00000086;
  --md-sys-color-box-shadow-top-dark: rgba(181, 243, 154, 0.3);
  --md-sys-color-box-shadow-bottom-dark: rgba(181, 243, 154, 0.47);
  --md-sys-color-navbar-box-shadow-dark: none;
  --md-sys-color-bg-dark: #000000;
  --md-sys-color-card-bg-dark: linear-gradient(to right, #bccbb014, transparent);
  --md-sys-color-skeleton-bg-dark: #8c8c8c1c;
  --md-sys-color-text-dark: #e2ffd5;
  --md-sys-effect-media-dark: 1.1;
  --md-sys-card-bg-image-color-dark: rgba(255, 255, 255, 0.03);

  /* display - large */
  --md-sys-typescale-display-large-font-family-name: Roboto;
  --md-sys-typescale-display-large-font-family-style: Regular;
  --md-sys-typescale-display-large-font-weight: 400px;
  --md-sys-typescale-display-large-font-size: 57px;
  --md-sys-typescale-display-large-line-height: 64px;
  --md-sys-typescale-display-large-letter-spacing: -0.25px;
  /* display - medium */
  --md-sys-typescale-display-medium-font-family-name: Roboto;
  --md-sys-typescale-display-medium-font-family-style: Regular;
  --md-sys-typescale-display-medium-font-weight: 400px;
  --md-sys-typescale-display-medium-font-size: 45px;
  --md-sys-typescale-display-medium-line-height: 52px;
  --md-sys-typescale-display-medium-letter-spacing: 0px;
  /* display - small */
  --md-sys-typescale-display-small-font-family-name: Roboto;
  --md-sys-typescale-display-small-font-family-style: Regular;
  --md-sys-typescale-display-small-font-weight: 400px;
  --md-sys-typescale-display-small-font-size: 36px;
  --md-sys-typescale-display-small-line-height: 44px;
  --md-sys-typescale-display-small-letter-spacing: 0px;
  /* headline - large */
  --md-sys-typescale-headline-large-font-family-name: Roboto;
  --md-sys-typescale-headline-large-font-family-style: Regular;
  --md-sys-typescale-headline-large-font-weight: 400px;
  --md-sys-typescale-headline-large-font-size: 32px;
  --md-sys-typescale-headline-large-line-height: 40px;
  --md-sys-typescale-headline-large-letter-spacing: 0px;
  /* headline - medium */
  --md-sys-typescale-headline-medium-font-family-name: Roboto;
  --md-sys-typescale-headline-medium-font-family-style: Regular;
  --md-sys-typescale-headline-medium-font-weight: 400px;
  --md-sys-typescale-headline-medium-font-size: 28px;
  --md-sys-typescale-headline-medium-line-height: 36px;
  --md-sys-typescale-headline-medium-letter-spacing: 0px;
  /* headline - small */
  --md-sys-typescale-headline-small-font-family-name: Roboto;
  --md-sys-typescale-headline-small-font-family-style: Regular;
  --md-sys-typescale-headline-small-font-weight: 400px;
  --md-sys-typescale-headline-small-font-size: 24px;
  --md-sys-typescale-headline-small-line-height: 32px;
  --md-sys-typescale-headline-small-letter-spacing: 0px;
  /* body - large */
  --md-sys-typescale-body-large-font-family-name: Roboto;
  --md-sys-typescale-body-large-font-family-style: Regular;
  --md-sys-typescale-body-large-font-weight: 400px;
  --md-sys-typescale-body-large-font-size: 16px;
  --md-sys-typescale-body-large-line-height: 24px;
  --md-sys-typescale-body-large-letter-spacing: 0.50px;
  /* body - medium */
  --md-sys-typescale-body-medium-font-family-name: Roboto;
  --md-sys-typescale-body-medium-font-family-style: Regular;
  --md-sys-typescale-body-medium-font-weight: 400px;
  --md-sys-typescale-body-medium-font-size: 14px;
  --md-sys-typescale-body-medium-line-height: 20px;
  --md-sys-typescale-body-medium-letter-spacing: 0.25px;
  /* body - small */
  --md-sys-typescale-body-small-font-family-name: Roboto;
  --md-sys-typescale-body-small-font-family-style: Regular;
  --md-sys-typescale-body-small-font-weight: 400px;
  --md-sys-typescale-body-small-font-size: 12px;
  --md-sys-typescale-body-small-line-height: 16px;
  --md-sys-typescale-body-small-letter-spacing: 0.40px;
  /* label - large */
  --md-sys-typescale-label-large-font-family-name: Roboto;
  --md-sys-typescale-label-large-font-family-style: Medium;
  --md-sys-typescale-label-large-font-weight: 500px;
  --md-sys-typescale-label-large-font-size: 14px;
  --md-sys-typescale-label-large-line-height: 20px;
  --md-sys-typescale-label-large-letter-spacing: 0.10px;
  /* label - medium */
  --md-sys-typescale-label-medium-font-family-name: Roboto;
  --md-sys-typescale-label-medium-font-family-style: Medium;
  --md-sys-typescale-label-medium-font-weight: 500px;
  --md-sys-typescale-label-medium-font-size: 12px;
  --md-sys-typescale-label-medium-line-height: 16px;
  --md-sys-typescale-label-medium-letter-spacing: 0.50px;
  /* label - small */
  --md-sys-typescale-label-small-font-family-name: Roboto;
  --md-sys-typescale-label-small-font-family-style: Medium;
  --md-sys-typescale-label-small-font-weight: 500px;
  --md-sys-typescale-label-small-font-size: 11px;
  --md-sys-typescale-label-small-line-height: 16px;
  --md-sys-typescale-label-small-letter-spacing: 0.50px;
  /* title - large */
  --md-sys-typescale-title-large-font-family-name: Roboto;
  --md-sys-typescale-title-large-font-family-style: Regular;
  --md-sys-typescale-title-large-font-weight: 400px;
  --md-sys-typescale-title-large-font-size: 22px;
  --md-sys-typescale-title-large-line-height: 28px;
  --md-sys-typescale-title-large-letter-spacing: 0px;
  /* title - medium */
  --md-sys-typescale-title-medium-font-family-name: Roboto;
  --md-sys-typescale-title-medium-font-family-style: Medium;
  --md-sys-typescale-title-medium-font-weight: 500px;
  --md-sys-typescale-title-medium-font-size: 16px;
  --md-sys-typescale-title-medium-line-height: 24px;
  --md-sys-typescale-title-medium-letter-spacing: 0.15px;
  /* title - small */
  --md-sys-typescale-title-small-font-family-name: Roboto;
  --md-sys-typescale-title-small-font-family-style: Medium;
  --md-sys-typescale-title-small-font-weight: 500px;
  --md-sys-typescale-title-small-font-size: 14px;
  --md-sys-typescale-title-small-line-height: 20px;
  --md-sys-typescale-title-small-letter-spacing: 0.10px;
}

