.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.ub-bg-clr_rgba67-90-111-0-7_groewj::before{
  backdrop-filter: blur(24px);
  background-color: transparent !important;
}

.ub-bg-clr_white {
  background-color: transparent !important
}

.ub-color_101840{
  color: var(--md-sys-color-on-primary) !important;
}

.ub-bg-clr_3366FF {
  background-color: var(--md-sys-color-surface-tint) !important;
  background-color: var(--md-sys-color-surface-tint) !important;
  border-top-color: var(--md-sys-color-primary) !important;
  border-bottom-color: var(--md-sys-color-primary) !important;
  border-left-color: var(--md-sys-color-primary) !important;
  border-right-color: var(--md-sys-color-primary) !important;
}

.ub-color_474d66{
  color: var(--md-sys-color-on-primary) !important;
}

.ub-fnt-sze_12px {
  font-size: 16px !important;
}

.ub-b-top-clr_ADC2FF_1bcl2f4:focus {
  border-top-color: var(--md-sys-color-on-surface) !important;
}

.ub-b-rgt-clr_ADC2FF_1bcl2f4:focus {
  border-right-color: var(--md-sys-color-on-surface) !important;
}

.ub-b-lft-clr_ADC2FF_1bcl2f4:focus {
  border-left-color:var(--md-sys-color-on-surface) !important;
}

.ub-b-btm-clr_ADC2FF_1bcl2f4:focus {
  border-bottom-color: var(--md-sys-color-on-surface) !important;
}

.ub-bs_13po50p_1bcl2f4:focus {
  box-shadow: 0 0 0 2px var(--md-sys-color-primary) !important;
}

.ub-bs_13po50p_xpy5ci:not([disabled]):focus {
  box-shadow: 0 0 0 2px var(--md-sys-color-primary) !important;
}


.ub-b-top-clr_d8dae5 {
  border-top-color: var(--md-sys-color-on-surface) !important;
}

.ub-b-rgt-clr_d8dae5 {
  border-right-color:var(--md-sys-color-on-surface) !important;
}

.ub-b-lft-clr_d8dae5 {
  border-left-color: var(--md-sys-color-on-surface) !important;
}

.ub-b-btm-clr_d8dae5 {
  border-bottom-color: var(--md-sys-color-on-surface) !important;
}

.ub-b-top_1px-solid-c1c4d6 {
  border-top-color: var(--md-sys-color-on-surface) !important;
}


.ub-b-rgt_1px-solid-c1c4d6 {
  border-right-color:var(--md-sys-color-on-surface) !important;
}

.ub-b-lft_1px-solid-c1c4d6 {
  border-left-color: var(--md-sys-color-on-surface) !important;
}

.ub-b-btm_1px-solid-c1c4d6 {
  border-bottom-color: var(--md-sys-color-on-surface) !important;
}

.ub-b-btm_1px-solid-edeff5 {
  border-bottom: var(--md-sys-color-on-surface) !important;
}

.ub-fill_696f8c {
  fill: var(--md-sys-color-on-primary) !important;
}

.ub-bg-clr_transparent {
  background-color: var(--md-sys-color-error-container) !important;
}

.ub-color_696f8c {
  color: var(--md-sys-color-on-secondary-container) !important;
}

.ub-bs_y4kng7_1bcl2f4:focus {
  box-shadow: 0px 0px 0px 2px var(--md-sys-color-primary) !important;
}

.ub-bg-clr_FAFBFF {
  background-color:  var(--md-sys-color-secondary) !important;
}

.ub-color_5C85FF {
  color: var(--md-sys-color-link)
}

.ub-bg-clr_edeff5 {
  background-color: var(--md-sys-color-on-surface) !important;
}

.ub-fill_c1c4d6 {
  fill: var(--md-sys-color-primary) !important;
}

.ub-bg-clr_F4F6FA {
  background-color: var(--md-sys-color-secondary) !important;
}

.ub-fill_8f95b2 {
  fill:  var(--md-sys-color-primary) !important;
}

.css-17vbkzs-MuiFormControl-root-MuiTextField-root {
  border: 1px solid var(--md-sys-color-on-surface) !important;
  border-radius: 4px;
  outline: none;
  background-color: var(--md-sys-color-secondary) !important;
  transition: box-shadow 80ms ease-in-out;

}

.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input, .css-b52kj1, .css-1y3zh1, .css-12yjm75-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--md-sys-color-on-primary) !important;
}

.css-i4bv87-MuiSvgIcon-root, .css-sxw2zh, .css-o1spff, .MuiChip-deleteIcon, .css-vubbuv {
  color: var(--md-sys-color-on-secondary-container) !important
}

.css-16lloyr-MuiBottomNavigation-root, .css-de1c49, .css-1ez9utm {
  background-color: var(--md-sys-color-primary) !important;
}

.css-1bjk3jo-MuiButtonBase-root-MuiBottomNavigationAction-root {
  color: var(--md-sys-color-on-primary) !important;
}

.css-gy4i07-MuiPaper-root {
  box-shadow: var(--md-sys-color-navbar-box-shadow) !important;
  margin-bottom: -2px;
}

input:-internal-autofill-selected {
  background-color: initial !important;
}

.ub-min-w_24px {
  color: var(--md-sys-color-error) !important
}

.ub-color_996A13 {
  color: var(--md-sys-color-on-warning-notification-background) !important;
}

.ub-bg-clr_FFFAF2 {
  background-color: var(--md-sys-color-warning-notification-background) !important;
}
  

.ub-bg-clr_F5FBF8 {
  background-color: var(--md-sys-color-success-notification-background) !important;
}

.ub-fill_FFB020 {
  fill: var(--md-sys-color-warning-notification-icon-fill) !important;
}

.ub-bg-clr_FDF4F4 {
  background-color: var(--md-sys-color-error-container-background) !important;
}
.css-11j1b2e-MuiInputBase-root-MuiOutlinedInput-root {
  background-color: var(--md-sys-color-secondary) !important;
}

.css-6od3lo-MuiChip-label, .css-xirb9b-MuiFormLabel-root-MuiInputLabel-root, .css-9iedg7, .css-chq866, .css-wjsjww-MuiChip-label {
  color: var(--md-sys-color-on-primary) !important;;
}

.css-38raov-MuiButtonBase-root-MuiChip-root, .css-o1spff , .MuiAutocomplete-tag{
  background-color: var(--md-sys-color-chip-background) !important;
}

.ub-bs_17bru36 {
  box-shadow: 0 0 1px var(--md-sys-color-box-shadow-top), 0 2px 4px -2px var(--md-sys-color-box-shadow-bottom) !important;
}

.css-ft4jij-MuiDivider-root,  .css-zy8cjw, .css-9mgopn-MuiDivider-root, .css-39bbo6, .css-lu6xi1-MuiDivider-root, .css-hmn930{
  border-color: var(--md-sys-color-box-shadow-top) !important;
}

.css-1xnbq41-MuiAutocomplete-root {
  border-radius: 4px !important;
  background: var(--md-sys-color-secondary) !important;
}

.ub-ml_1-1em {
  margin-left: 0px !important;
}

.ub-b-btm_1px-solid-E6E8F0 {
    border-bottom: 1px solid #000000 !important;
}

.custom-focused .MuiOutlinedInput-notchedOutline {
  border-color: darkgreen !important;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  background-color: transparent !important;
}

.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop, .css-919eu4  {
  backdrop-filter: blur(2px) !important;
}

.css-1n54m00-MuiModal-root-MuiDialog-root, .MuiDialogContent-root, .css-1eh6s3y{
  padding: 0px !important;
}

.ub-a_wxhuzj {
  animation-duration: 1s !important;
}

.ub-strk_rgba67-90-111-0-47 {
  stroke: var(--md-sys-color-text) !important ;
}

.css-m9glnp-MuiPaper-root-MuiDialog-paper, .css-1hyl1h2  {
  background-color: transparent !important;
}

.css-3i94e7-MuiInputBase-root-MuiOutlinedInput-root, .css-5mjz24 {
  border-radius: 0px !important;
  border-top: 1px solid var(--md-sys-color-primary);
}

.css-v59wfm {
  font-size: small !important;
}

.css-1yxmbwk {
  margin-left: -0.8em !important;
}

.css-5xe99f-MuiLinearProgress-bar1, .css-t752vm{
  background-color: var(--md-sys-color-on-primary) !important;
}

.video-js .vjs-tech {
  filter: brightness(var(--md-sys-effect-media)) contrast(var(--md-sys-effect-media));
  background-color: transparent !important;
}


.vjs-youtube.vjs-user-inactive .vjs-iframe-blocker{
  display: none !important
}


.ub-pb_24px {
  padding-bottom: 4px !important ;
}

.css-14gf62f-MuiSlider-thumb {
  border-radius: 0% !important;
}

.css-14gf62f-MuiSlider-thumb.Mui-active{
  box-shadow: 0px 0px 0px 8px var(--md-sys-color-chip-background) !important;
}

.css-1r7ws3c-MuiSlider-root {
  padding-bottom: 0px !important
}

.shape {
  transition: all 1s ease-in-out;
  border-radius: 50%; 
  -webkit-animation: morph 20s ease-in-out infinite alternate;
  animation: morph 20s ease-in-out infinite alternate;
}

@-webkit-keyframes morph {
  0% {
    border-radius: 50%; /* Start with a circle */
    background-color: #3498db;
  }
  100% {
    border-radius: 0%; /* Morph into a square */
    background-color: #e74c3c;
  }
}

@keyframes morph {
  0% {
    border-radius: 50%;
    background-color: #3498db;
  }
  100% {
    border-radius: 0%;
    background-color: #e74c3c;
  }
}



.ub-z-idx_30 {
  z-index: 200 !important;
}

.css-9emuhu-MuiPaper-root-MuiDrawer-paper, .css-fy2uxz {
  background-color: var(--md-sys-color-secondary) !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked, .css-1nr2wod.Mui-checked  {
  color: var(--md-sys-color-on-primary) !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track, .css-1ju1kxc{
  background-color: var(--md-sys-color-on-secondary) !important;
}

/* .css-1bjk3jo-MuiButtonBase-root-MuiBottomNavigationAction-root {
min-width: 39px !important;
} */